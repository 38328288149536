import { PoolConfig, PoolCategory } from './types'
// import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 4,
    tokenName: 'IRIS',
    stakingTokenName: 'SANDMAN',
    stakingTokenAddress: '0xf9b4dEFdDe04fe18F5ee6456607F8A2eC9fF6A75',
    contractAddress: {
      137: '0xE25b865BB08bda79ff5Ac50bd12cBEd916758e68',
    },
    lpAddress: {
      137: '0x86ad6271809f350522085F95F5A67d46ff7ed3AB',
    },
    tokenAddress: {
      137: '0xdaB35042e63E93Cc8556c9bAE482E5415B5Ac4B1',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.PARTNERSHIP,
    projectLink: 'https://www.hermesdefi.io/',
    harvest: true,
    tokenPerBlock: '0.1553130435',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 3000,
    withdrawLockup: 72,
    harvestLockup: 4,
    partnerColor: '52, 136, 147',
  },
  {
    sousId: 3,
    tokenName: 'SPADE',
    stakingTokenName: 'SANDMAN',
    stakingTokenAddress: '0xf9b4dEFdDe04fe18F5ee6456607F8A2eC9fF6A75',
    contractAddress: {
      137: '0xeEa70935C90BfD4A5fA4254F69dAff761Bb062eF',
    },
    lpAddress: {
      137: '0xCcCEc4A90b3435065f5e1feC6346be9Da1B7B5eD',
    },
    tokenAddress: {
      137: '0xf5EA626334037a2cf0155D49eA6462fDdC6Eff19',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.PARTNERSHIP,
    projectLink: 'https://polygonfarm.finance/',
    harvest: true,
    tokenPerBlock: '0.00390625',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 1000,
    withdrawLockup: 36,
    harvestLockup: 2,
    partnerColor: '119, 81, 216',
  },
  {
    sousId: 2,
    tokenName: 'CRYSTL',
    stakingTokenName: 'SANDMAN',
    stakingTokenAddress: '0xf9b4dEFdDe04fe18F5ee6456607F8A2eC9fF6A75',
    contractAddress: {
      137: '0xB2cB1D7625f65C7BCc137Cd4c82A847a72Bfc395',
    },
    lpAddress: {
      137: '0xB8e54c9Ea1616beEBe11505a419DD8dF1000E02a',
    },
    tokenAddress: {
      137: '0x76bF0C28e604CC3fE9967c83b3C3F31c213cfE64',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.PARTNERSHIP,
    projectLink: 'https://polycrystal.finance/',
    harvest: true,
    tokenPerBlock: '0.063516260162602',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 5000,
    withdrawLockup: 72,
    harvestLockup: 1,
    partnerColor: '216, 148, 214',
  },
  // {
  //   sousId: 1,
  //   tokenName: 'DAI',
  //   stakingTokenName: 'MORPHEUS',
  //   stakingTokenAddress: '0x0D30a539F0597F01cA34356515318a4Fb6Ffe7CB',
  //   contractAddress: {
  //     137: '0xc3d8DAE26E105d26Bef5E27362D260a41664729a',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://polycrystal.finance/',
  //   harvest: true,
  //   tokenPerBlock: '1',
  //   sortOrder: 2,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
]

export default pools
