import React, { useContext } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { allLanguages } from 'config/localisation/languageCodes'
import { LanguageContext } from 'contexts/Localisation/languageContext'
import useTheme from 'hooks/useTheme'
import { usePriceCakeBusd } from 'state/hooks'
import { Menu as UikitMenu, MenuEntry } from '@pancakeswap-libs/uikit'
// import contracts from '../../config/constants/contracts'
import BigNumber from 'bignumber.js'
// import config from './config'
// import { getRefUrl } from '../../utils'
import contracts from '../../config/constants/contracts'

// const refer = getRefUrl()
// hack to force the set of the cockie.
// const refParam = refer === '0x0000000000000000000000000000000000000000' ? '' : `?ref=${refer}`
// console.log('ref', refParam)

const Menu = (props) => {
  const { account, connect, reset } = useWallet()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const token = contracts.cake[process.env.REACT_APP_CHAIN_ID]

  let cakePriceUsd = usePriceCakeBusd()
  if (process.env.REACT_APP_PRICE_DEFAULT) {
    cakePriceUsd = new BigNumber(process.env.REACT_APP_PRICE_DEFAULT)
  }

  const config = () => {
    const cfg: MenuEntry[] = [
      {
        label: 'Home',
        icon: 'HomeIcon',
        href: props.onPresale ? '/home' : '/',
      },
      {
        label: 'Dream Kingdom',
        icon: 'IfoIcon',
        href: '/dream-kingdom',
        // tag: {
        //   title: 'LIVE',
        //   variant: 'secondary',
        // }
      },
      {
        label: 'Vaults',
        icon: 'VaultsIcon',
        href: '/vaults',
        tag: {
          title: 'auto-compound',
          variant: 'primary',
        }
      },
      {
        label: 'The Endless',
        icon: 'LayerIcon',
        href: '/the-endless',
      },
      {
        label: 'Farms',
        icon: 'FarmIcon',
        href: '/farms',
      },
      {
        label: 'Pools',
        icon: 'PoolIcon',
        href: '/pools',
      },
      {
        label: 'Tokens',
        icon: 'MetamaskIcon',
        href: 'https://metamask.sandman.farm',
      },
      // {
      //   label: 'Referral',
      //   icon: 'ReferralIcon',
      //   href: '/referral',
      // },
      // {
      //   label: 'Lottery (coming soon)',
      //   icon: 'TicketIcon',
      //   href: '/lottery',
      // },
      // {
      //   label: 'Prediction (coming soon)',
      //   icon: 'PredictionIcon',
      //   href: '/lottery',
      // },
      // {
      //   label: 'NFT',
      //   icon: 'NftIcon',
      //   href: '/nft',
      // },
      // {
      //   label: 'Features',
      //   icon: 'FeaturesIcon',
      //   items: [
      //     {
      //       label: 'Automatic LP',
      //       href: 'https://docs.sandman.farm/tokenomics/automatic-liquidity',
      //     },
      //     {
      //       label: 'Automatic Burning',
      //       href: 'https://docs.sandman.farm/tokenomics/automatic-burning',
      //     },
      //     {
      //       label: 'Deposit Shield',
      //       href: 'https://docs.sandman.farm/deposit-shield',
      //     },
      //     {
      //       label: 'Anti-Whale',
      //       href: 'https://docs.sandman.farm/tokenomics/anti-whale',
      //     },
      //   ],
      // },
      {
        label: 'Charts',
        icon: 'InfoIcon',
        items: [
          {
            label: 'PooCoin (SANDMAN)',
            href: `https://polygon.poocoin.app/tokens/${token}`,
          },
          {
            label: 'Arken (SANDMAN)',
            href: `https://swap.arken.finance/tokens/polygon/${token}`,
          },
      //     {
      //       label: 'QuickChart (SANDMAN)',
      //       href: `https://quickchart.app/token/${token}`,
      //     },
      //     {
      //       label: 'PolyChart (SANDMAN)',
      //       href: `https://app.polychart.io/explorer/polygon/${token}`,
      //     },
        ],
      },
      {
        label: 'Listings',
        icon: 'ListingIcon',
        items: [
          {
            label: 'Vfat',
            href: 'https://vfat.tools/polygon/sandmanfarm/',
          },
          {
            label: 'CoinGecko',
            href: 'https://www.coingecko.com/en/coins/sandman',
          },
          {
            label: 'GemIndex',
            href: 'https://gemindex.io/',
          },
          {
            label: 'CoinMarketCap',
            href: 'https://coinmarketcap.com/currencies/sandman/',
          },
      //     {
      //       label: 'PolygonScan',
      //       href: `https://polygonscan.com/token/${token}`,
      //     },
          {
            label: 'CoinSniper',
            href: 'https://coinsniper.net/coin/12445',
          },
          {
            label: 'DappRadar (MORPHEUS)',
            href: 'https://dappradar.com/polygon/defi/sandman-farm',
          },
      //     {
      //       label: 'CoinStats',
      //       href: 'https://coinstats.app/coins/dojofarm-finance',
      //     },
      //     {
      //       label: 'LiveCoinWatch',
      //       href: 'https://www.livecoinwatch.com/price/DojoFarmFinance-SANDMAN',
      //     },
        ],
      },
      {
        label: 'Tutorials',
        icon: 'DocsIcon',
        href: '/tutorials',
      },
      {
        label: 'Team',
        icon: 'GroupsIcon',
        href: '/team',
      },
    ]

    if (props.onPresale) {
      cfg.splice(0, 0, 
        {
          label: 'Pre-sale',
          icon: 'NftIcon',
          href: '/',
        }
      )
    } else {
      cfg.push(
        {
          label: 'Pre-sale (ended)',
          icon: 'NftIcon',
          href: '/presale',
        }
      )
    }

    if (cakePriceUsd.isNaN()) {
      cfg.push(
        {
          label: 'Trade (coming soon)',
          icon: 'TradeIcon',
          href: '#',
        }
      )
    } else {
      cfg.splice(props.onPresale ? 2 : 1, 0,
        {
          label: 'Trade',
          icon: 'TradeIcon',
          items: [
            {
              label: 'Exchange',
              href: `https://quickswap.exchange/#/swap?outputCurrency=${token}`,
            },
            {
              label: 'Liquidity',
              href: `https://quickswap.exchange/#/add/${token}/ETH`,
            },
          ],
        }
      )
    }

    return cfg
  }

  return (
    <UikitMenu
      account={account}
      login={connect}
      logout={reset}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage && selectedLanguage.code}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={cakePriceUsd.toNumber()}
      links={config()}
      priceLink={`https://polygon.poocoin.app/tokens/${token}`}
      rugDocLink="https://rugdoc.io/project/sandman/"
      paladinLink="https://paladinsec.co/projects/sandman/"
      paladinText="PALADIN AUDITED"
      {...props}
    />
  )
}

export default Menu
