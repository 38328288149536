import contracts from './contracts'
import { VaultConfig, QuoteToken } from './types'

const vaults: VaultConfig[] = [
  {
    pid: 0,
    farmPid: 1,
    isTokenOnly: true,
    lpSymbol: 'SANDMAN',
    decimal: 18,
    lpAddresses: {
      137: '0x56de3448C9EE1ADCb05F26f5093b593D492695c8',
    },
    tokenSymbol: 'SANDMAN',
    tokenAddresses: {
      137: '0xf9b4dEFdDe04fe18F5ee6456607F8A2eC9fF6A75',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'USDC',
    earnDecimal: 6,
    from: 'Sandman',
    abiFile: 'vaultSandManStrategy',
    strategyAddresses: {
      137: '0x89BF8234E36ABcf91207C747B388E0cE3B30A4E3',
    },
    compoundFrequency: 288,
    performanceFee: 0,
  },
  {
    pid: 2,
    farmPid: 14,
    lpSymbol: 'SANDMAN-MATIC',
    decimal: 18,
    lpAddresses: {
      137: '0x3cd18d001a9152b3157A18C3cAe7b7F4b7e1977B',
    },
    tokenSymbol: 'SANDMAN',
    tokenAddresses: {
      137: '0xf9b4dEFdDe04fe18F5ee6456607F8A2eC9fF6A75',
    },
    quoteTokenSymbol: QuoteToken.MATIC,
    quoteTokenAdresses: contracts.wmatic,
    earnSymbol: 'SANDMAN-MATIC',
    earnDecimal: 18,
    from: 'Sandman',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0xea72ad4Fe798daD0bCAed71c67d4b791856e29D3',
    },
    compoundFrequency: 288,
    performanceFee: 6.66,
  },
  {
    pid: 1,
    farmPid: 0,
    lpSymbol: 'SANDMAN-USDC',
    decimal: 18,
    lpAddresses: {
      137: '0x56de3448C9EE1ADCb05F26f5093b593D492695c8',
    },
    tokenSymbol: 'SANDMAN',
    tokenAddresses: {
      137: '0xf9b4dEFdDe04fe18F5ee6456607F8A2eC9fF6A75',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'SANDMAN-USDC',
    earnDecimal: 18,
    from: 'Sandman',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0x29E57EEBa20D5aC9F425D1961C721CAE58C0E0d0',
    },
    compoundFrequency: 288,
    performanceFee: 6.66,
  },
]

export default vaults
