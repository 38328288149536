import addresses from 'config/constants/contracts'

const chainId = process.env.REACT_APP_CHAIN_ID

export const getCakeAddress = () => {
  return addresses.cake[chainId]
}
export const getUsdcAddress = () => {
  return addresses.usdc[chainId]
}
export const getMasterChefAddress = () => {
  return addresses.masterChef[chainId]
}
export const getVaultChefAddress = () => {
  return addresses.vaultChef[chainId]
}
export const getMulticallAddress = () => {
  return addresses.mulltiCall[chainId]
}
export const getWbnbAddress = () => {
  return addresses.wbnb[chainId]
}
export const getLotteryAddress = () => {
  return addresses.lottery[chainId]
}
export const getSandmanReferralAddress = () => {
  return addresses.sandmanReferral[chainId]
}
export const getMorpheusPresaleAddress = () => {
  return addresses.morpheusPresale[chainId]
}
export const getMorpheusRedeemAddress = () => {
  return addresses.morpheusRedeem[chainId]
}
export const getLotteryTicketAddress = () => {
  return addresses.lotteryNFT[chainId]
}
