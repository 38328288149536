export default {
  cake: {
    137: '0xf9b4dEFdDe04fe18F5ee6456607F8A2eC9fF6A75',
  },
  masterChef: {
    137: '0x898b5332dE3f0E87B363B0A47DE41C50b3C6E6A1',
  },
  vaultChef: {
    137: '0x9BBE8F85E3D92a5bA6378602C5F4B6d2071eF2BE',
  },
  lottery: {
    137: '',
  },
  sandmanReferral: {
    137: '0xFDdDeacd2Ffc5F5Da430265430427F2669d2eA14',
  },
  morpheusPresale: {
    137: '0x0D30a539F0597F01cA34356515318a4Fb6Ffe7CB',
  },
  morpheusRedeem: {
    137: '0x1bc0f0A2707F713FF6Eb6Bbdc2EE55B6b3705832',
  },
  endlessChef: {
    137: '0x394c2Ee8c24A61e606741841899CC29b36eAc377',
  },
  lotteryNFT: {
    137: '',
  },
  mulltiCall: {
    137: '0xEF3d4160E3d193E3d1494a4A9cF9eEA05E63a324',
  },
  wmatic: {
    137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  matic: {
    137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  wbnb: {
    137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  bnb: {
    137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  wbtc: {
    137: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
  },
  weth: {
    137: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  eth: {
    137: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  usdc: {
    137: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  busd: {
    137: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  usdt: {
    137: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
  },
  dai: {
    137: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
  },
  quick: {
    137: '0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
  },
  link: {
    137: '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
  },
}
